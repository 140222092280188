<template>
    <div>


        <v-card id="user-list"
                class="mt-6">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-color">
                        {{ $t('Packages Listing') }}
                        </span>
                    <!-- </div> -->
                </div>
                <div class="d-flex align-center pb-5">

                    <!-- <v-text-field single-line
                                  dense
                                  outlined
                                  hide-details
                                  prepend-inner-icon="mdi-sort-variant"
                                  placeholder="Sort by Category"
                                  class="user-list-search me-3"></v-text-field> -->

                    <v-btn color="primary text-lower"
                           @click="addPackageForm">
                        {{ $t('Add Package') }}
                    </v-btn>
                </div>
            </v-card-text>
            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :options.sync="options"
                          :server-items-length="totalUserListTable"
                          class="text-no-wrap my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <!-- <template #[`item.id`]="{ item }">
                    <router-link class="font-weight-medium text-decoration-none"
                                 :to="{ name: 'user-form', params: { id: item.id } }">
                        <div class="text-black font-size">{{ item.id }}</div>
                    </router-link>
                </template> -->
                <template #item.name="{ item }">
                    <div class="text-black font-size">{{ item.name }}</div>
                </template>
                <template #item.price="{ item }">
                    <div class="text-black font-size">{{ item.price }}</div>
                </template>
                <template #item.description="{ item }">
                    <div class="text-black text-wrap font-size">{{ item.description }}</div>
                </template>

                <template #[`item.role_id`]="{ item }">
                    {{ resolveRole(item.role_id) }}
                </template>

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <!-- approve -->
                        <!-- v-if="item.users_verification.status !== 'approved' && item.users_verification.status !== 'rejected'" -->




                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       color="#00B2A9"
                                       @click="editData(item)"
                                       v-on="on">
                                    <v-icon size="18">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="viewData(item)"
                                       v-on="on">
                                    <v-icon size="20">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>View</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>


        </v-card>
    </div>
</template>

<script>
import packageServices from '@/services/package-services';
import { mdiEyeOutline } from '@mdi/js';

// composition function
import CancellationDialog from '@/views/CancellationDialog.vue';
import usePackageList from './useMyPackageList';

export default {
    props: {
        listingValue: {
            default: false,
            type: Boolean
        },
    },
    components: {
        CancellationDialog
    },
    data: () => ({
        applicationList: '',
        boatOwners: [],
        admin: false
    }),
    mounted()
    {
        
        // this.getAllOwners();
        // const userData = JSON.parse(localStorage.getItem('userData'));
        // if (userData.role == 'admin')
        // {
        //     this.admin = true;
        // }
        if (this.listingValue)
        {
            this.options = [];
        }
    },
    methods: {
        editData(item)
        {
            
            this.$emit('editForm', item);
        },
        viewData(item)
        {
            
            this.$emit('viewForm', item);
        },
        addPackageForm()
        {
            this.$emit('showForm');
        },
        getAllOwners()
        {
        },
        updateUserStatus(item)
        {

            let url;
            let body;
            if (item.status == '1')
            {
                url = 'boat_owner_packages/activate'
                body = {
                    "id": item.id,
                    "status": "1"
                }
            }
            else
            {
                url = 'boat_owner_packages/deactivate'
                body = {
                    "id": item.id,
                    "status": "2"
                }
            }
            packageServices.updateStatus(url, body).then(resp =>
            {
                console.log(resp);
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: '#00b2a9',
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    let status = resp.data.boat_owner_package.status == '1' ? 'Activate' : 'DeActivate';
                    this.$swal({
                        title: `Status ${status}`,
                        confirmButtonColor: '#00b2a9',
                        icon: 'success',
                    }).then(result =>
                    {
                        // refresh here
                    })
                }
            })
        }
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            packageId,
            resolveRole,
        } = usePackageList()

        return {
            tableColumns,
            searchQuery,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            packageId,
            resolveRole,

            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.text-color {
    color: #00B2A9;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 3.125rem;
    border-left: 1px solid #efeded !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(94, 86, 105, 0.14);
    border-right: 1px solid #efeded;
}
</style>
