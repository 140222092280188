<template>
    <div class="pa-16"
         v-bind:class="{ 'bg-img': submitEmail }">
        <packageDialog @closed="closeDialog"
                       :packageInfos="ownerPackage"
                       :boatCreationId="boatCreationId"
                       @updatePackageData="updatePackageData"
                       @createPackageData="createPackageData"
                       :modal="modall"
                       :disableForm="disableForm"></packageDialog>

        <div class="d-flex">
            <v-row style="justify-content: end;"
                   class="pt-4"
                   v-if="submitEmail">
                <appBar :submitEmail="submitEmail"></appBar>
            </v-row>
            <v-row style="justify-content: end;"
                   v-if="!submitEmail"
                   class="pt-4">
                <appBar :submitEmail="submitEmail"></appBar>
            </v-row>
        </div>


        <div class="d-flex justify-center mt-2"
             v-if="submitEmail"
             style="color:white;font-size:18px;">
            <v-img :src="require(`@/assets/images/logos/myboat-logo.svg`)"
                   max-width="147px"
                   alt="logo"
                   contain
                   class="mt-2"></v-img>
        </div>


        <v-stepper v-if="submitEmail"
                   v-model="e6"
                   alt-labels
                   class="mt-9 pa-0">
            <div class="text-green mt-5 d-flex justify-center"
                 style="font-size:18px;">
                {{ $t('Registration Process') }}

            </div>
            <v-stepper-header class="mt-0">
                <v-stepper-step step="1"
                                :complete="e6 > 1"
                                @click="goToStep(1)"
                                style="cursor:pointer;">
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2"
                                v-bind:class="{ 'diableDiv': !boatOwnerFields }"
                                :complete="e6 > 2"
                                @click="goToStep(2)"
                                style="cursor:pointer;"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step v-bind:class="{ 'diableDiv': !ownerInformationFields }"
                                :complete="e6 > 3"
                                step="3"
                                @click="goToStep(3)"
                                style="cursor:pointer;"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step v-bind:class="{ 'diableDiv': !packagesPricingFields }"
                                :complete="e6 > 4"
                                step="4"
                                @click="goToStep(4)"
                                style="cursor:pointer;"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step v-bind:class="{ 'diableDiv': !boatConditionFields }"
                                :complete="e6 == 6"
                                step="5"
                                @click="goToStep(5)"
                                style="cursor:pointer;"></v-stepper-step>
            </v-stepper-header>
        </v-stepper>





        <v-form class="mt-4 multi-col-validation"
                lazy-validation
                ref="form"
                v-model="valid"
                v-if="submitEmail">
            <v-stepper v-model="step"
                       class="py-5 px-10">
                <v-stepper-content step="1">
                    <div class="d-flex align-center">
                        <span class="font-weight-semibold text-base text-green">
                            {{ $t('General Boat Information') }}
                        </span>
                    </div>
                    <v-row class="mt-0">
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Boat Name') }}
                            </p>
                            <v-text-field :placeholder="$t('Boat Name')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields"
                                          v-model="myboat.boat_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Boat Name Ar') }}
                            </p>
                            <v-text-field :placeholder="$t('Boat Name Ar')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields write-ar"
                                          v-model="myboat.boat_name_ar"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Category') }}
                            </p>
                            <v-select :items="categories"
                                      :placeholder="$t('Category')"
                                      variant="underlined"
                                      item-text="name"
                                      item-value="id"
                                      class="text-fields"
                                      :rules="[genericRulesTwo.required]"
                                      @change="selectCategory"
                                      v-model="myboat.category_id"></v-select>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Boat Brand') }}
                            </p>
                            <v-text-field :placeholder="$t('Boat Brand')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields"
                                          v-model="myboat.boat_brand"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('City') }}
                            </p>
                            <v-select :items="cities"
                                      :placeholder="$t('City')"
                                      variant="underlined"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[genericRulesTwo.required]"
                                      class="text-fields"
                                      @change="getMarinaByCityId(myboat.city_id)"
                                      v-model="myboat.city_id"></v-select>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Marina') }}
                            </p>
                            <v-select :items="marinas"
                                      :placeholder="$t('Marina')"
                                      variant="underlined"
                                      item-text="name"
                                      :readonly="!myboat.city_id"
                                      @change="onMarinaChange(myboat.marina_id)"
                                      item-value="id"
                                      :rules="[genericRulesTwo.required]"
                                      class="text-fields"
                                      v-model="myboat.marina_id"></v-select>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12"
                               v-if="myboat.marina_id == -1">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Marina Name') }} *
                            </p>
                            <v-text-field :placeholder="$t('Marina Name')"
                                          variant="underlined"
                                          class="text-fields"
                                          :disabled="view"
                                          v-model="myboat.marina_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12"
                               v-if="myboat.marina_id == -1">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Marina Name Ar') }} *
                            </p>
                            <v-text-field :placeholder="$t('Marina Name Ar')"
                                          variant="underlined"
                                          class="text-fields write-ar"
                                          :disabled="view"
                                          v-model="myboat.marina_name_ar"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Model') }}
                            </p>
                            <v-text-field :placeholder="$t('Model')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields"
                                          v-model="myboat.boat_modal"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Engine Brand/ Model') }}
                            </p>
                            <v-text-field :placeholder="$t('Engine Brand')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields"
                                          v-model="myboat.engine_model">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Year') }}
                            </p>
                            <v-select :items="yearList"
                                      :placeholder="$t('Select Year')"
                                      variant="underlined"
                                      :rules="[genericRulesTwo.required]"
                                      class="text-fields"
                                      v-model="myboat.year"></v-select>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Location') }}
                            </p>
                            <v-text-field :placeholder="$t('Location')"
                                          variant="underlined"
                                          :rules="[genericRulesTwo.required]"
                                          class="text-fields"
                                          v-model="myboat.location"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Latitude') }}
                            </p>
                            <v-text-field :placeholder="$t('Add Latitude')"
                                          variant="underlined"
                                          :rules="[genericRules.required, genericRules.numbers]"
                                          class="text-fields"
                                          :disabled="view"
                                          v-model="myboat.latitude"></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Longitude') }}
                            </p>
                            <v-text-field :placeholder="$t('Add Longitude')"
                                          variant="underlined"
                                          :rules="[genericRules.required, genericRules.numbers]"
                                          class="text-fields"
                                          :disabled="view"
                                          v-model="myboat.longitude"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Service & Maintenance Time') }}
                            </p>
                            <v-select :items="time"
                                      :placeholder="$t('Select Time')"
                                      variant="underlined"
                                      :rules="[genericRulesTwo.required]"
                                      item-text="name"
                                      item-value="id"
                                      class="text-fields"
                                      v-model="myboat.maintenance_time"></v-select>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12"
                               v-if="showLengthField">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Boat Length') }}
                            </p>
                            <v-select :items="boatLengthValues"
                                      :placeholder="$t('Boat Length')"
                                      variant="underlined"
                                      suffix="ft"
                                      :rules="[genericRulesTwo.required]"
                                      class="text-fields"
                                      v-model="myboat.boat_length"></v-select>
                        </v-col>

                        <v-col cols="12"
                               md="6"
                               lg="12"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('General Boat Description') }}
                            </p>
                            <v-textarea solo
                                        auto-grow
                                        outlined
                                        class="mt-2"
                                        v-model="myboat.description"
                                        :label="$t('Enter General Boat Description')">
                            </v-textarea>
                        </v-col>
                        <v-col cols="12"
                               md="6"
                               lg="12"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('General Boat Description Ar') }}
                            </p>
                            <v-textarea solo
                                        auto-grow
                                        outlined
                                        class="mt-2"
                                        v-model="myboat.description_ar"
                                        :label="$t('Enter General Boat Description Ar')">
                            </v-textarea>
                        </v-col>

                    </v-row>
                    <div class="mt-5">
                        <v-btn color="primary"
                               class="text-lower"
                               v-if="!view"
                               :disabled="!fieldIsFilled"
                               @click="createBoatInfo">
                            {{ $t('Next') }}
                        </v-btn>
                    </div>
                </v-stepper-content>


                <v-stepper-content step="2">
                    <div class="d-flex align-center">
                        <span class="font-weight-semibold text-base text-green">
                            {{ $t('Owner Information') }}
                        </span>
                    </div>
                    <v-row class="mt-0">
                        <v-col cols="12"
                               md="6"
                               lg="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Name') }} *
                            </p>
                            <v-text-field :placeholder="$t('Owner Name')"
                                          variant="underlined"
                                          :rules="genericRules"
                                          class="text-fields"
                                          v-model="myboat.owner_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="6"
                               lg="4"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Name Ar') }} *
                            </p>
                            <v-text-field :placeholder="$t('Owner Name Ar')"
                                          variant="underlined"
                                          :rules="genericRules"
                                          class="text-fields write-ar"
                                          v-model="myboat.owner_name_ar"></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Owner Type') }}
                            </p>
                            <v-select :placeholder="$t('Owner Type')"
                                      variant="underlined"
                                      :items="allOwnerTypes"
                                      class="text-fields"
                                      v-model="myboat.type"></v-select>
                        </v-col>
                        <!-- <v-col cols="12"
                               md="6"
                               sm="12">

                        </v-col> -->
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('ID No.') }} *
                            </p>
                            <v-text-field :placeholder="$t('ID No.')"
                                          variant="underlined"
                                          :rules="genericRules"
                                          class="text-fields"
                                          v-model="myboat.id_no"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Certificate of Owner Registration') }}
                            </p>
                            <v-text-field :placeholder="$t('Certificate of Owner Registration')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.certification"></v-text-field>

                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Country') }} *
                            </p>
                            <v-select :items="allCountries"
                                      :placeholder="$t('Country')"
                                      variant="underlined"
                                      @change="selectCountry"
                                      item-text="name"
                                      :rules="genericRules"
                                      class="text-fields"
                                      v-model="myboat.country_id"></v-select>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Unit') }}
                            </p>
                            <v-text-field :placeholder="$t('Unit')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.unit"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Street') }}
                            </p>
                            <v-text-field :placeholder="$t('Street')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.street"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('District') }}
                            </p>
                            <v-text-field :placeholder="$t('District')"
                                          variant="underlined"
                                          @input="validateInput"
                                          class="text-fields"
                                          v-model="myboat.district"></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('City') }} *
                            </p>
                            <v-select :items="allCities"
                                      :placeholder="$t('City')"
                                      variant="underlined"
                                      item-text="name"
                                      :rules="genericRules"
                                      class="text-fields"
                                      v-model="myboat.city"></v-select>

                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Email ID') }} *
                            </p>
                            <v-text-field :placeholder="$t('Email')"
                                          variant="underlined"
                                          :rules="[emailRule.required, emailRule.email]"
                                          @blur="handleEmail"
                                          type="email"
                                          class="text-fields"
                                          v-model="myboat.bank_email"></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Phone') }} *
                            </p>
                            <vue-phone-number-input v-model="myboat.bank_phone"
                                                    @input="handlePhone"
                                                    :default-country-code="'SA'"
                                                    style="border: none;"
                                                    :color="'#00b2a9'"
                                                    :minLength="8"
                                                    :maxlength="11"
                                                    class="code-select"
                                                    :disable-dropdown="true" />
                            <span v-if="isPhoneLength"
                                  style="color: red;font-size: 11px;">Please enter valid Mobile Number</span>

                        </v-col>

                    </v-row>

                    <v-row class="mt-5">
                        <v-col cols="12"
                               md="12"
                               sm="12">
                            <div class="d-flex align-center">
                                <span class="text-fields font-weight-semibold text-base text-green">
                                    {{ $t('Account Information') }}
                                </span>
                            </div>
                        </v-col>

                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Bank Name') }}
                            </p>
                            <v-text-field :placeholder="$t('Bank name')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.bank_name"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Branch') }}
                            </p>
                            <v-text-field :placeholder="$t('Branch')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.branch"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('IBAN') }}
                            </p>
                            <v-text-field :placeholder="$t('IBAN')"
                                          variant="underlined"
                                          class="text-fields"
                                          v-model="myboat.iban"></v-text-field>
                        </v-col>

                    </v-row>
                    <div class="mt-5"
                         v-if="!view">
                        <v-btn class="mr-4 text-lower"
                               @click="goToStep(1)"
                               style="border: 1px solid #00B2A9;">
                            {{ $t('Back') }}
                        </v-btn>
                        <v-btn color="primary"
                               class="text-lower"
                               :disabled="!boatOwnerField"
                               @click="boatOwner">
                            {{ $t('Next') }}
                        </v-btn>
                    </div>
                </v-stepper-content>


                <v-stepper-content class="bg"
                                   step="3">
                    <v-card class="pa-3 mt-2">
                        <v-card-text class="d-flex align-center flex-wrap pb-0">
                            <div class="d-flex align-center pb-5">
                                <div class="d-flex align-center">
                                    <span class="font-weight-semibold text-base text-green">
                                        {{ $t('Rates') }}
                                    </span>
                                </div>
                            </div>

                            <v-spacer></v-spacer>

                            <div class="d-flex align-center pb-5">

                            </div>
                        </v-card-text>

                        <v-card-text>
                            <v-row class="">
                                <v-col v-if="category_type != -1"
                                       cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Booking Type') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Booking Type')"
                                                  variant="underlined"
                                                  :readonly="true"
                                                  class="text-fields"
                                                  v-model="bookingType"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ category_type == '0' ? $t('Day Range') : $t('Slot Time') }}
                                    </p>
                                    <v-text-field v-if="category_type == '0'"
                                                  :placeholder="category_type == '0' ? $t('Day Range') : $t('Slot Time')"
                                                  variant="underlined"
                                                  :suffix="category_type == '0' ? 'Day(s)' : 'Hour(s)'"
                                                  :readonly="true"
                                                  class="text-fields"
                                                  v-model="otherSlotTime"></v-text-field>

                                    <v-select v-if="category_type == '1'"
                                              :items="slotTimeValues"
                                              variant="underlined"
                                              item-text="title"
                                              item-value="value"
                                              item-disabled="disabled"
                                              :placeholder="category_type == '0' ? $t('Day Range') : $t('Slot Time')"
                                              :rules="[genericRulesTwo.required]"
                                              class="text-fields"
                                              v-model="myboat.slot_time"></v-select>
                                </v-col>

                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('Select Price (SAR)') }}
                                    </p>
                                    <v-text-field :placeholder="$t('Enter Price in SAR')"
                                                  variant="underlined"
                                                  v-mask="currencyMask"
                                                  :rules="[genericRulesTwo.required]"
                                                  class="text-fields"
                                                  v-model="myboat.price"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        Additional Time Rate
                                    </p>
                                    <v-text-field :placeholder="$t('Enter Price in SAR')"
                                                  variant="underlined"
                                                  v-mask="currencyMask"
                                                  :rules="[genericRulesTwo.required, genericRules.numbers]"
                                                  class="text-fields"
                                                  v-model="myboat.additional_period_rate"></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12"
                                       lg="3"
                                       md="6"
                                       sm="12"
                                       v-if="ownerRole">
                                    <p class="font-weight-semibold mb-0 text-black">
                                        {{ $t('MyBoat Commission') }}
                                    </p>
                                    <v-select :items="percentageValues"
                                              variant="underlined"
                                              :item-text="`name`"
                                              :placeholder="$t('Enter Percentage (0-100%)')"
                                              suffix="%"
                                              :rules="[genericRulesTwo.required]"
                                              class="text-fields"
                                              v-model="myboat.percentage"></v-select>
                                </v-col> -->
                            </v-row>
                            <!-- <div class="mt-5"
                                 v-if="!view">
                                <v-btn color="primary"
                                       class="text-lower"
                                       :disabled="!packagingForm"
                                       @click="addPricing">
                                    {{ $t('Next') }}
                                </v-btn>
                            </div> -->
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3"
                            v-if="boatCreationId != null">
                        <packageList @showForm="showForm"
                                     @viewForm="viewForm"
                                     ref="childRef"
                                     :listingValue="listingValue"
                                     @editForm="editForm"></packageList>

                    </v-card>
                    <div class="mx-4 mt-4">
                        <v-btn class="mr-4 text-lower"
                               @click="goToStep(2)"
                               style="border: 1px solid #00B2A9;">
                            {{ $t('Back') }}
                        </v-btn>
                        <v-btn color="primary"
                               @click="nextPackage"
                               :disabled="!packagingForm"
                               class="text-lower">
                            {{ $t('Next') }}
                        </v-btn>
                    </div>

                </v-stepper-content>


                <v-stepper-content step="4">
                    <v-card-text class="d-flex align-center flex-wrap pb-0">
                        <div class="d-flex align-center pb-5">
                            <div class="d-flex align-center">
                                <span class="font-weight-semibold text-base text-green">
                                    {{ $t('Boat Condition and Features') }}
                                </span>
                            </div>
                        </div>

                        <v-spacer></v-spacer>

                        <div class="d-flex align-center pb-5">

                        </div>
                    </v-card-text>
                    <v-row class="mt-0 mx-2">
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('License No.') }}
                            </p>
                            <v-text-field variant="underlined"
                                          :placeholder="$t('License No.')"
                                          class="text-fields"
                                          v-model="myboat.license_number"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Last Service Report') }}
                            </p>

                            <v-menu v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field variant="underlined"
                                                  append-icon="mdi-calendar-month"
                                                  @click:append="on.click"
                                                  v-bind="attrs"
                                                  :readonly="true"
                                                  :placeholder="$t('Service Report')"
                                                  v-on="on"
                                                  class="text-fields"
                                                  v-model="myboat.report">
                                        <template v-slot:append>
                                            <v-icon @click="on.click"
                                                    color="#00B2A9">mdi-calendar-month</v-icon>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="myboat.report"
                                               @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Last Anti Fouling Date') }}
                            </p>
                            <v-menu v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field variant="underlined"
                                                  append-icon="mdi-calendar-month"
                                                  @click:append="on.click"
                                                  v-bind="attrs"
                                                  :readonly="true"
                                                  :placeholder="$t('Anti fouling')"
                                                  v-on="on"
                                                  class="text-fields"
                                                  v-model="myboat.anti_fouling">
                                        <template v-slot:append>
                                            <v-icon @click="on.click"
                                                    color="#00B2A9">mdi-calendar-month</v-icon>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="myboat.anti_fouling"
                                               @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>


                        <v-col cols="12"
                               lg="12"
                               md="6"
                               sm="12">
                            <table class="mt-1">
                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/paint.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Paint') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="paint"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/polish.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Polish') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="polish"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>


                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/anti.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Anti Fouling') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="anti_fouling"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>

                                    <!-- <td style="width: 241px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Polish') }}</td>

                                    <td style="cursor: pointer; width: 105px;"
                                        class="font-weight-bold"><v-switch v-model="Polish"

                                                  value="1"
                                                  inset
                                                  hide-details></v-switch></td>

                                    <td style="width: 211px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Anti Fouling') }}</td>
                                    <td style="cursor: pointer;"
                                        class="font-weight-bold"><v-switch v-model="fouling"

                                                  value="1"
                                                  inset
                                                  hide-details></v-switch></td> -->

                                </tr>




                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/washroom.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Washroom') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="wash_room"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/clean.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Cleanliness') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="cleanliness"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>


                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/jackets.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Life Jackets') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="life_jacket"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>



                                </tr>



                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/fire.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Fire Extinguisher') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="fire_extinguisher"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/ac.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('AC') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="air_conditioner"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>


                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/rooms.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Rooms') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="rooms"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                </tr>



                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/salon.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Salon') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="salon"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/smoke.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Smoke') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="smoke"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>


                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/sound.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Gearbox Sound') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="gear_sound_box"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                </tr>



                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/bimini.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Bimini') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="bimini"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/gps.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('GPS') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="gps"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>


                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/sounder.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('Depth Sounder') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="depth_sounder"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>
                                </tr>


                                <tr style="height: 40px;">
                                    <td style="width: 53px; cursor: pointer;">
                                        <div>
                                            <img class="attach-file"
                                                 :src="require(`@/assets/images/usb.png`)"
                                                 alt="">
                                        </div>
                                    </td>
                                    <td style="width: 165px; cursor: pointer;"
                                        class="font-weight-bold">{{ $t('USB Socket') }}</td>
                                    <v-spacer></v-spacer>
                                    <td style="cursor: pointer; width: 108px;"
                                        class="font-weight-bold"><v-switch v-model="usb_socket"
                                                  inset
                                                  value="1"
                                                  hide-details></v-switch></td>

                                </tr>
                            </table>
                        </v-col>


                    </v-row>
                    <div class="mt-5 mx-6"
                         v-if="!view">

                        <v-btn text
                               class="mr-4 text-lower"
                               style="border: 1px solid #00B2A9;"
                               @click="goToStep(3)">
                            {{ $t('Back') }}
                        </v-btn>
                        <v-btn color="primary"
                               class="text-lower"
                               @click="boatCondition">
                            {{ $t('Next') }}
                        </v-btn>
                    </div>
                </v-stepper-content>


                <v-stepper-content class="bg"
                                   step="5">
                    <v-card class="pa-3 mt-2">
                        <v-card-text class="d-flex align-center flex-wrap pb-0">
                            <div class="d-flex align-center pb-5">
                                <div class="mx-2 d-flex align-center">
                                    <span class="font-weight-semibold text-base text-green">
                                        {{ $t('Boat Documents') }}
                                    </span>
                                </div>
                            </div>

                            <v-spacer></v-spacer>

                            <div class="d-flex align-center pb-5">

                            </div>
                        </v-card-text>

                        <v-card-text>
                            <v-row class="mx-2 py-3">
                                <p class="text-black">{{ $t('Upload Document') }}</p>
                                <v-col sm="6"
                                       md="4"
                                       lg="12">

                                    <v-row class="mr-3">
                                        <div v-for="( field, index ) in  boatDocument "
                                             :key="field"
                                             class="upload-docu mt-3 mr-3"
                                             style="padding:0px !important"
                                             tile>
                                            <input type="file"
                                                   name="file"
                                                   id="fileUpload1"
                                                   class="hidden-input"
                                                   ref="file"
                                                   @change="uploadBoatDocument"
                                                   hidden
                                                   accept=".pdf,.word,.doc,.docx,.xls,.xlsx,.ppt,.pptx" />


                                            <span v-if="field.image"
                                                  style="cursor: pointer;"
                                                  class="document-icon">
                                                <v-img :src="require(`@/assets/images/pdf.png`)"
                                                       height="50px"
                                                       width="55px"
                                                       v-if="field.type == 'pdf'"></v-img>
                                                <v-img :src="require(`@/assets/images/word.png`)"
                                                       height="50px"
                                                       width="55px"
                                                       v-if="field.type == 'vnd.openxmlformats-officedocument.wordprocessingml.document'"></v-img>
                                                <v-img :src="require(`@/assets/images/powerpoint.png`)"
                                                       height="50px"
                                                       width="55px"
                                                       v-if="field.type == 'vnd.openxmlformats-officedocument.presentationml.presentation'"></v-img>

                                                <v-img :src="require(`@/assets/images/excel.png`)"
                                                       height="50px"
                                                       width="55px"
                                                       v-if="field.type == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></v-img>

                                                <div class="text-black"
                                                     style="padding-top:4px; font-size:9px;">{{ field.name }}</div>
                                            </span>
                                            <!-- <v-img v-if="field.image"
                                                   :src="field.image"
                                                   width="100%"
                                                   style="border-radius: 10px;height: 100%;max-height: 154px;"
                                                   class="me-2"></v-img> -->



                                            <span v-if="field.image && !view"
                                                  class="category-icon"
                                                  style="cursor: pointer;"
                                                  @click="deleteBoatDocument(field, index)">
                                                <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                                       height="21px"
                                                       width="22px"></v-img>
                                            </span>

                                            <span v-if="updatedBoatDocuments && field.image"
                                                  @click="perviewFile(field)"
                                                  style="cursor: pointer;"
                                                  class="document-icon">
                                                <v-img :src="require(`@/assets/images/pdf.png`)"
                                                       height="50px"
                                                       width="55px"></v-img>

                                            </span>

                                            <label for="fileInput"
                                                   class="file-label">
                                                <img v-if="!field.image"
                                                     class="imge"
                                                     :src="require(`@/assets/images/document.png`)"
                                                     alt="">
                                            </label>
                                            <div v-if="!field.image"
                                                 style="cursor: pointer;"
                                                 class="document-text"
                                                 @click="chooseFiles1(index)">
                                                {{ $t('Upload Document') }}
                                            </div>
                                        </div>
                                        <div v-if="!view"
                                             v-bind:class="{ 'diableDiv': !hasUploadImage }"
                                             class="upload-docu mt-3"
                                             style="padding:0px !important"
                                             tile>

                                            <img class="plus-img"
                                                 style="cursor: pointer;"
                                                 :disabled="true"
                                                 @click="addBoatDocument()"
                                                 :src="require(`@/assets/images/plus.png`)"
                                                 alt="">

                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <div class="mt-5 mx-2"
                                 v-if="!view">
                                <v-btn class="mr-4"
                                       style="border: 1px solid #00B2A9;"
                                       @click="goToStep(4)">
                                    {{ $t('Back') }}
                                </v-btn>
                                <v-btn color="primary"
                                       class="text-lower"
                                       @click="submitDocument">
                                    {{ $t('Save') }}
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card class="pa-3 mt-4">
                        <v-card-text class="d-flex align-center flex-wrap pb-0">
                            <div class="d-flex align-center pb-5">
                                <div class="mx-2 d-flex align-center">
                                    <span class="font-weight-semibold text-base text-green">
                                        {{ $t('Boat Pictures') }}
                                    </span>
                                </div>
                            </div>

                            <v-spacer></v-spacer>

                        </v-card-text>

                        <v-card-text>
                            <div class="mx-2 py-3">
                                <p class="text-black">{{ $t('Upload Picture') }}</p>
                                <v-col sm="6"
                                       md="4"
                                       lg="12">

                                    <v-row class="mr-3">
                                        <div v-for="( field, index ) in  boatPictures"
                                             :key="field"
                                             class="upload-docu mt-3 mr-3"
                                             style="padding:0px !important"
                                             tile>
                                            <input type="file"
                                                   name="file"
                                                   id="boatGallery"
                                                   class="hidden-input"
                                                   ref="file"
                                                   @change="uploadPicture"
                                                   hidden
                                                   accept=".jpg,.jpeg,.png" />
                                            <v-img v-if="field.image"
                                                   :src="field.image"
                                                   width="100%"
                                                   style="border-radius: 10px;height: 100%;max-height: 154px;"
                                                   class="me-2"></v-img>


                                            <span v-if="field.image && !view"
                                                  class="category-icon"
                                                  @click="deleteBoatPicture(field, index)">
                                                <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                                       height="21px"
                                                       width="22px"></v-img>
                                            </span>
                                            <label for="fileInput"
                                                   class="file-label">
                                                <img v-if="!field.image"
                                                     class="imge"
                                                     :src="require(`@/assets/images/dummy-img.png`)"
                                                     alt="">
                                            </label>
                                            <div v-if="!field.image"
                                                 style="cursor: pointer;"
                                                 class="upload-img"
                                                 @click="chooseFilesGallery(index)">
                                                {{ $t('Upload Image') }}
                                            </div>
                                        </div>

                                        <div v-if="!view"
                                             class="upload-docu mt-3"
                                             v-bind:class="{ 'diableDiv': !hasUploadPicture }"
                                             style="padding:0px !important"
                                             tile>

                                            <img class="plus-img"
                                                 style="cursor: pointer;"
                                                 @click="addBoatPicture()"
                                                 :src="require(`@/assets/images/plus.png`)"
                                                 alt="">

                                        </div>
                                    </v-row>


                                </v-col>
                            </div>
                            <div class="mt-5 mx-2"
                                 v-if="!view">
                                <v-btn class="mr-4 text-lower"
                                       @click="goToStep(4)"
                                       style="border: 1px solid #00B2A9;">
                                    {{ $t('Back') }}
                                </v-btn>
                                <v-btn color="primary text-lower"
                                       class="text-lower"
                                       @click="submitPicture">
                                    {{ $t('Save') }}
                                </v-btn>
                            </div>

                            <v-row class="d-flex justify-center">
                                <div class="mt-5 mx-2">
                                    <v-btn @click="nextPage"
                                           color="primary text-lower">
                                        {{ $t('Submit') }}
                                    </v-btn>
                                </div>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="6">
                    <v-card height="390">
                        <v-card-text>
                            <div class="container">
                                <div class="d-flex justify-center">
                                    <captcha></captcha>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>


                </v-stepper-content>
            </v-stepper>
        </v-form>



        <v-card-text v-if="!submitEmail">
            <div class="container mt-10">
                <div class="d-flex justify-center">
                    <span class="font-weight-semibold text-green"
                          style="font-size:30px;">
                        {{ $t('Thank you!') }}
                    </span>
                </div>

                <div class="d-flex justify-center mt-9">
                    <p class="font-weight-semibold text-base"
                       style="margin-bottom:0px;">
                        {{ $t('Your request for registration has been submitted') }}
                    </p>
                </div>

                <div class="d-flex justify-center mb-0">
                    <p class="font-weight-semibold text-base">
                        {{ $t('Our team will be in contact with you shortly') }}
                    </p>
                </div>


                <div class="mt-7 d-flex justify-center">
                    <v-btn color="primary"
                           @click="closeForm"
                           class="text-lower">
                        {{ $t('Close') }}
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </div>
</template>
<script>

import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';
import config from '/config';

import countries from './countries';

import router from '@/router';
import boatServices from '@/services/boat-services';
import packageServices from '@/services/package-services';
import sharedServices from '@/services/shared-services';

import captcha from '@/views/Captcha.vue';

import packageList from '@/views/MyPackageList.vue';
import packageDialog from '@/views/PackageDialouge.vue';
import
{
    mdiPlus, mdipluscircle
} from '@mdi/js';

import appBar from '@core/layouts/components/app-bar/AppBarI18n.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import createNumberMask from "text-mask-addons/dist/createNumberMask";


export default {
    components: {
        packageList,
        packageDialog,
        captcha,
        appBar,
        VuePhoneNumberInput
    },
    data: () => ({
        currencyMask: createNumberMask({
            prefix: "",
            includeThousandsSeparator: true,
            allowNegative: false,
            allowDecimal: true,
            thousandsSeparatorSymbol: ",",
        }),
        bookingType: 'Hourly',
        otherSlotTime: '',
        percentageValues: [],
        steps: ['Step 1', 'Step 2', 'Step 3', 'Step 4'],
        activeStep: 0,
        e6: 1,
        valid: false,
        view: false,
        yourValue: '',
        countries: ['SA'],
        listingValue: false,
        genericRules: [
            v => !!v || 'Field is required'
        ],
        emailRule: {
            required: v => !!v || 'Field is required',
            email: v => /^[^@]+@\w+(\.\w+)+\w$/.test(v) || 'E mail must be valid',
        },
        numberRule: [
            value => !!value || 'Field is required.',
            value => (value && value.length >= 7) || 'Number must be 7 digits.',
        ],
        lengthRules: [
            v => !!v || "This field is required"
        ],
        genericRulesTwo: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
        },
        exampleBoatRules: [
            v => !!v || "This field is required",
            v => (v && parseInt(v) >= 15) || "Boat length must be between 14 - 48",
            v => (v && parseInt(v) <= 48) || "Boat length must be between 14 - 48",
        ],
        exampleYatchRules: [
            v => !!v || "This field is required",
            v => (v && parseInt(v) >= 36) || "Boat length must be between 36 - 200",
            v => (v && parseInt(v) <= 200) || "Boat length must be between 36 - 200",
        ],


        characters: v => /^[a-zA-Z]*$/.test(v) || 'Only characters are allowed',

        priceRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[0-9]*$/.test(v) || 'Only numbers are allowed'
        },
        phoneNumberRules: {
            required: v => !!v || 'Field is required',
            numbers: v => (v && v.length <= 10) || 'Number must be 10 digits.',
        },
        allOwnerTypes: ['Individual', 'Corporate'],
        allCities: [],
        cities: [],
        allCountries: countries.getCountry(),
        image: null,
        countries: [],
        value: true,
        boatGalleryUpload: [],
        hasUploadImage: true,
        boatOwnerFields: false,
        ownerInformationFields: false,
        boatConditionFields: false,
        packagesPricingFields: false,
        galleryFile: null,
        menu2: false,
        updatedBoatPicture: false,
        ownerPackage: {},
        menu3: false,
        category_type: 0,
        formFields: [

        ],
        boatPictures: [

        ],
        boatDocument: [

        ],
        slotTimeValues: [
            {
                title: '1 Hour',
                value: '01:00:00',
                disabled: false
            },
            {
                title: '2 Hours',
                value: '02:00:00',
                disabled: false
            },
            {
                title: '3 Hours',
                value: '03:00:00',
                disabled: false
            },
            {
                title: '4 Hours',
                value: '04:00:00',
                disabled: false
            },
            {
                title: '5 Hours',
                value: '05:00:00',
                disabled: false
            },
            {
                title: '6 Hours',
                value: '06:00:00',
                disabled: false
            },
            {
                title: '7 Hours',
                value: '07:00:00',
                disabled: false
            },
            {
                title: '8 Hours',
                value: '08:00:00',
                disabled: false
            },
            {
                title: '9 Hours',
                value: '09:00:00',
                disabled: false
            },
            {
                title: '10 Hours',
                value: '10:00:00',
                disabled: false
            },
            {
                title: '11 Hours',
                value: '11:00:00',
                disabled: false
            },
            {
                title: '12 Hours',
                value: '12:00:00',
                disabled: false
            }
        ],
        latitude: null,
        longitude: null,
        rates: [],
        packagesList: [],
        boatCreationId: null,
        categories: [],
        ex11: ['success'],
        panel: [0, 1, 2, 3],
        type: 'Add',
        yearList: [],
        // latitude: '',
        // longitude: '',
        rooms: '',
        paint: '',
        anti_fouling: '',
        polish: '',
        wash_room: '',
        cleanliness: '',
        fileIndex: null,
        packageImage: null,
        life_jacket: '',
        bimini: '',
        'usb_socket': '',
        'depth_sounder': '',
        hasUploadPicture: true,
        'gps': '',
        packageFile: null,
        submitEmail: true,
        salon: '',
        adddocu: false,
        smoke: '',
        packageItems: [],
        packageItem: '',
        packgeName: [],
        fire_extinguisher: '',
        ownerId: null,
        gear_sound_box: '',
        air_conditioner: '',
        fileType: '',
        allPorts: [
        ],
        marinas: [
        ],
        time: [
            {
                name: '1 Hour',
                id: '01:00:00'
            },
            {
                name: '2 Hours',
                id: '02:00:00'
            },
            {
                name: '3 Hours',
                id: '03:00:00'
            },
            {
                name: '4 Hours',
                id: '04:00:00'
            },
            {
                name: '5 Hours',
                id: '05:00:00'
            },
            {
                name: '6 Hours',
                id: '06:00:00'
            },
            {
                name: '7 Hours',
                id: '07:00:00'
            },
            {
                name: '8 Hours',
                id: '08:00:00'
            },
            {
                name: '8 Hours',
                id: '08:00:00'
            },
            {
                name: '9 Hours',
                id: '09:00:00'
            },
            {
                name: '10 Hours',
                id: '10:00:00'
            },
            {
                name: '11 Hours',
                id: '11:00:00'
            },
            {
                name: '12 Hours',
                id: '12:00:00'
            }
        ],
        showLengthField: true,
        boatOwners: [],
        myboat: {
            boat_name: '',
            boat_name_ar: '',
            city: '',
            country: '',
            port_name: '',
            city_name: '',
            marina_id: null,
            marina_name: '',
            marina_name_ar: '',
            latitude: null,
            longitude: null,
            port: '',
            cancellation_time: '',
            boatOwnerId: null,
            category_id: '',
            package: '',
            boat_brand: '',
            package_name: [],
            city_id: '',
            model: '',
            engine_model: '',
            year: '',
            location: null,
            language: '',
            owner_name: '',
            owner_name_ar: '',
            slot_time: '',
            price: '',
            percentage: '0',
            package_price: '',
            package_description: '',
            maintenance_time: '',
            unit: '',
            type: '',
            street: '',
            id_no: '',
            district: '',
            certification: '',
            owner_city: '',
            report: null,
            country_id: '1',
            structure_no: '',
            bank_name: '',
            branch: '',
            bank_phone: '',
            bank_email: '',
            iban: '',
            license_no: '',
            paint: '',
            polish: '',
            anti_fouling: '',
            washroom: '',
            cleanliness: '',
            last_anti_fouling_date: '',
            life_jackets: '',
            fire_extinguisher: '',
            ac_condition: '',
            rooms: '',
            salon: '',
            port_side_engine_hrs: '',
            stbd_engine_hrs: '',
            appearance: '',
            engine_sound: '',
            performance: '',
            smoke: '',
            last_service_report: '',
            gearbox_sound: '',
            throttle_condition: '',
            images: [],
            owner_id: null,
            bank_iban: null,
            boat_certificate: null,
            navigation_certificate: null,
            // slot_time: '',
            // price: '',
            // percentage: '',
            // package_name: '',
            additional_period_rate: '',
            description: null
        },
        step: 1,
        options: [],
        disableForm: false,
        info: true,
        ownerinfo: false,
        packages: false,
        boatcondition: false,
        document: false,
        packageId: null,
        boatDocumentUpload: [],
        updatedBoatDocuments: false,
        modall: false,
        status: '',
        ownerRole: true,
        totalDocumentSize: 0,
        totalPicturesSize: 0,
        cityTextField: false,
        portTextField: false,
        isPhoneLength: false,
        boatLengthValues: [],
    }),

    computed: {
        // isPhoneNumberValid()
        // {
        //     return this.myboat.bank_phone && this.myboat.bank_phone.length === 10;
        // },
        isValidEmail()
        {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.myboat.bank_email);
        },

        latLngInput: {
            get()
            {
                console.log(this.longitude, this.latitude)
                if (this.latitude !== null && this.longitude !== null)
                {
                    return this.latitude + ',' + this.longitude;
                } else
                {
                    return '';
                }
            },
            set(value)
            {
                const values = value.split(',');
                // this.latitude = values[0]?.trim();
                // this.longitude = values[1]?.trim() ? values[1]?.trim() : '';
                // console.log(this.longitude, this.latitude)
            },
        },
        fieldIsFilled()
        {
            if (this.myboat.marina_id == -1 && this.myboat.marina_name == '')
            {
                return false
            }

            return this.myboat.boat_name && this.myboat.boat_name && this.myboat.category_id && this.myboat.boat_brand && this.myboat.boat_modal && this.myboat.engine_model
                && this.myboat.year && this.myboat.city_id && this.myboat.marina_id;
        },

        boatOwnerField()
        {
            return this.myboat.owner_name && this.myboat.owner_name_ar && this.myboat.id_no && this.myboat.country_id
                && this.myboat.city && this.myboat.bank_phone && this.myboat.bank_email;
        },

        packagingForm()
        {
            return this.myboat.price && this.myboat.additional_period_rate;
        },

        checkBoatCondition()
        {
            return this.myboat.report && this.myboat.anti_fouling && this.myboat.license_number
        }

        // packageField()
        // {
        //     return this.myboat.price && this.myboat.percentage;
        // }
    },
    mounted()
    {
        // this.category_type = store.get('boatRegistrations/bookingType');

        for (let i = 1; i <= 99; i++)
        {
            this.percentageValues.push(i);
        }


        this.myboat.country_id = this.allCountries[0];
        this.allCities = countries.getCities(this.myboat.country_id);


        this.getCities();
        this.getCountries();
        this.getCategories();
        this.getYear();
    },
    methods: {
        generateNumbersArray(first, second)
        {
            this.boatLengthValues = [];
            for (let i = first; i <= second; i++)
            {
                this.boatLengthValues.push(i);
            }
        },
        selectCategory(value)
        {
            this.categories.filter(element =>
            {
                if (element.id == value)
                {
                    this.category_type = element.category_booking_type;

                    this.showLengthField = true;
                    if (element.name.toLowerCase().trim() == 'boat')
                    {
                        this.generateNumbersArray(14, 48);
                    }
                    else if (element.name.toLowerCase().trim() == 'yacht')
                    {
                        this.generateNumbersArray(36, 200);
                    }
                    else
                    {
                        this.showLengthField = false;
                        this.lengthRules = [
                            v => !!v || "This field is required"
                        ];
                        this.myboat.boat_length = 0;
                    }

                    this.myboat.slot_time = element.min_time_slot;

                    // store.set('boatRegistrations/slotTime', this.myboat.slot_time);
                    // store.set('boatRegistrations/bookingType', element.category_booking_type);

                    if (this.category_type == 0)
                    {
                        this.bookingType = 'Daily';
                        this.otherSlotTime = '01';
                        this.myboat.slot_time = '24:00:00'
                    }
                    else if (this.category_type == 1)
                    {
                        this.bookingType = 'Hourly';

                        if (this.myboat.slot_time)
                        {
                            this.slotTimeValues.forEach((element, index) =>
                            {
                                element.disabled = true;
                                let v = this.myboat.slot_time.split(':');
                                let num = parseInt(v[0]);

                                if (num <= index + 1)
                                {
                                    element.disabled = false;
                                }
                            });
                        }
                    }
                }
            })

        },
        goToStep(num)
        {

            this.e6 = num;
            this.step = num;
        },
        handleEmail()
        {

            if (!this.isValidEmail)
            {
                console.log('email invalid')
                this.myboat.bank_email = '';
                return;
            }
        },
        handlePhone()
        {


            if (this.myboat.bank_phone.length < 8)
            {
                console.log('phone invalid')
                // this.myboat.bank_phone = '';
                this.isPhoneLength = true;
                return;
            }
            else
            {
                this.isPhoneLength = false;
            }
        },
        validateInput(event)
        {
            // Get the key code of the pressed key
            const keyCode = event.keyCode || event.which;

            // Allow alphabets (a-z, A-Z), spaces (32), and backspace (8)
            const validKeyCodes = [32, 8];
            for (let i = 65; i <= 90; i++)
            {
                validKeyCodes.push(i);
            }
            for (let i = 97; i <= 122; i++)
            {
                validKeyCodes.push(i);
            }

            // If the pressed key is not in the valid key codes, prevent it
            if (!validKeyCodes.includes(keyCode))
            {
                event.preventDefault();
            }
        },
        updatePackageData(event)
        {
            packageServices.updatePackage(event).then(resp =>
            {
                console.log();
                if (resp.statusCode == 200)
                {
                    this.$swal({
                        title: ``,
                        text: 'Package updated successfully.',
                        confirmButtonColor: '#00b2a9',
                        icon: 'success',
                    }).then((result) =>
                    {
                        // router.push('/myboat/list')
                    });
                    this.modall = false;
                    this.$refs.childRef.packageId = this.boatCreationId;
                    this.$refs.childRef.options = [{
                        page: 1,
                        itemsPerPage: 10
                    }];

                }
                else
                {
                    console.log('package error')
                }
            });
        },
        createPackageData(event)
        {

            packageServices.createPackage(event).then(resp =>
            {
                console.log();
                if (resp.statusCode == 200)
                {
                    this.$swal({
                        title: ``,
                        text: 'Package Added Successfully.',
                        confirmButtonColor: '#00b2a9',
                        icon: 'success',
                    }).then((result) =>
                    {
                        // router.push('/myboat/list')
                        this.packageItems = event;
                    });
                    this.modall = false;
                    this.$refs.childRef.packageId = this.boatCreationId;
                    this.$refs.childRef.options = [{
                        page: 1,
                        itemsPerPage: 10
                    }];

                }
                else
                {
                    console.log('package error')
                }
            });

        },
        onMarinaChange(id)
        {
            let cityObject = this.marinas.find(x => x.id === id);

            console.log(cityObject);

            if (cityObject)
            {
                this.myboat.latitude = cityObject.latitude == null ? 0 : cityObject.latitude;
                this.myboat.longitude = cityObject.longitude == null ? 0 : cityObject.longitude;

                this.latLngInput = this.myboat.latitude + ',' + this.myboat.longitude;
            }
        },
        getMarinaByCityId(id)
        {
            this.marinas = [];

            sharedServices.getMarinaByCityId(id).then(resp =>
            {
                console.log(resp)
                this.marinas = resp.data.marinas;
                this.marinas.push({ id: -1, name: 'Other' });

                console.log(this.marinas);
            })
        },
        showForm()
        {

            this.ownerPackage = {};
            this.packageName = '';
            this.modall = true;
            this.disableForm = false;
        },
        viewForm(item)
        {

            console.log(item)
            this.ownerPackage = item;
            this.modall = true;
            this.disableForm = true;
        },
        editForm(item)
        {

            console.log(item)
            this.ownerPackage = item;
            this.modall = true;
            this.disableForm = false;
        },
        selectCountry()
        {
            console.log(this.myboat.country_id)
            this.allCities = countries.getCities(this.myboat.country_id);
        },
        closeDialog()
        {
            this.modall = false;
        },
        viewPackage(element)
        {

            console.log(element);
            this.modall = true;
            this.ownerPackage = element;
        },
        perviewFile(element)
        {
            if (element.id)
            {
                window.open(element.image, '_blank');
            }
        },
        nextPage()
        {
            this.goToStep(6)
            this.step = 7;
            this.submitEmail = false;
            window.scrollTo(0, 0);
            boatServices.submitTemplate(this.boatCreationId).then(res =>
            {
                console.log(res);
            })
        },
        submitPicture()
        {
            console.log(this.boatGalleryUpload);
            if (this.boatGalleryUpload.length)
            {
                const formData = new FormData();
                formData.append('boat_id', this.boatCreationId);
                this.boatGalleryUpload.forEach(element =>
                {
                    formData.append('boat_pictures', element);
                });
                boatServices.submitBoatGallery(formData).then(res =>
                {
                    if (res.status == 200)
                    {
                        this.boatGalleryUpload = [];
                        this.$swal({
                            title: ``,
                            text: 'Image Uploaded Successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {
                            // router.push('/myboat/list')
                        });
                    }
                    else
                    {
                        this.boatGalleryUpload = [];

                    }
                });
            }
            else
            {
                return;
            }


        },
        closeForm()
        {

            // this.step = 1;
            window.location.replace('http://myboat.co/')
        },
        getYear()
        {

            var max = new Date().getFullYear()
            var min = max - 53

            for (var i = max; i >= min; i--)
            {
                this.yearList.push(i)
            }
        },
        remove(index)
        {

            console.log(this.packageItem);
            this.packageItems.splice(index, 1);
            console.log(this.packageItem);
        },
        backToList()
        {
            router.push({ name: 'myboat-list' })
        },

        getBoatDocument()
        {
            boatServices.getBoatDocument(this.boatCreationId).then(res =>
            {
                if (res.status == 200)
                {
                    if (res.data.data.boat.boat_documents)
                    {
                        this.updatedBoatDocuments = true;
                        res.data.data.boat.boat_documents.forEach((element, index) =>
                        {
                            this.boatDocument.push({
                                image: config.MYBOAT_IMG_URL + element.document,
                                id: element.id
                            });
                            console.log(element.image)
                        });
                    }
                    console.log(this.boatDocument);
                }
            });
        },

        getBoatPicture()
        {

            boatServices.getBoatPicture(this.boatCreationId).then(res =>
            {
                if (res.status == 200)
                {
                    if (res.data.data.boat.boat_pictures)
                    {
                        this.updatedBoatPicture = true;
                        res.data.data.boat.boat_pictures.forEach((element, index) =>
                        {
                            this.boatPictures.push({
                                image: config.MYBOAT_IMG_URL + element.image,
                                id: element.id
                            });
                            console.log(element.image)
                        });
                    }
                    console.log(this.boatPictures);
                }
            });
        },

        submitDocument()
        {
            if (this.boatDocumentUpload.length)
            {
                console.log(this.boatDocumentUpload);
                const formData = new FormData();
                formData.append('boat_id', this.boatCreationId);
                this.boatDocumentUpload.forEach(element =>
                {
                    formData.append('boat_documents', element);
                });
                boatServices.submitBoatDocument(formData).then(res =>
                {
                    if (res.statusCode == 200)
                    {
                        this.$swal({
                            title: ``,
                            text: 'Document Uploaded Successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {
                            // router.push('/myboat/list')
                        });
                    }
                    else
                    {
                        console.log('document error')
                    }
                });
            }
            else
            {

            }

        },

        addPackage()
        {

            console.log(this.packageItem);
            if (!this.packageItem)
            {
                return;
            }
            else
            {
                if (this.packageId)
                {

                    if (this.packageItem.includes(","))
                    {
                        let value = this.packageItem.split(",");
                        let newpackg = value[value.length - 1];
                        console.log(newpackg);
                        this.packageItems.push(newpackg);
                        this.packageItem = [];
                        this.packageItem.push(this.packageItems);
                        console.log(this.packageItems);
                    }
                    else
                    {
                        this.packageItems.push(this.packageItem);
                    }
                }
                else
                {
                    this.packageItems.push(this.packageItem);
                    this.packageItem = '';
                    console.log(this.packageItem);
                }
            }
        },
        uploadBoatDocument(event)
        {
            let file = event.target.files[0];
            this.fileType = file.type.split('/');
            if (this.fileType[1] == 'pdf' || this.fileType[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || this.fileType[1] == 'vnd.openxmlformats-officedocument.presentationml.presentation' || this.fileType[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            {
                let fileSize = Math.round(event.target.files[0].size / 1024);
                console.log(this.totalDocumentSize);
                if (fileSize <= 3072)
                {
                    this.boatDocumentUpload.push(event.target.files[0]);
                    console.log(file);
                    return new Promise((resolve, reject) =>
                    {
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = () =>
                        {
                            this.image = reader.result;
                            this.boatDocument[this.fileIndex].image = this.image;
                            this.boatDocument[this.fileIndex].name = event.target.files[0].name;
                            this.boatDocument[this.fileIndex].type = this.fileType[1];
                            this.hasUploadImage = true;
                            // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            // console.log(this.packageFile);
                            // this.uploadcardLimitImage(base64Image, file.size, file.name)

                        };
                    })
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Boat Document',
                        confirmButtonColor: '#00b2a9',
                        text: 'select file less than 3 MB'
                    }).then((result) =>
                    {

                    });
                }

            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Boat Document',
                    confirmButtonColor: '#00b2a9',
                    text: 'Invalid Format!'
                }).then((result) =>
                {

                });
            }


        },
        uploadPicture(event)
        {
            console.log(this.formFields);
            let file = event.target.files[0];
            let type = file.type.split('/');
            if (type[1] == 'png' || type[1] == 'jpeg' || type[1] == 'jpg')
            {
                let fileSize = Math.round(event.target.files[0].size / 1024);
                // this.totalPicturesSize = this.totalPicturesSize + fileSize;
                if (fileSize <= 3072)
                {
                    this.boatGalleryUpload.push(event.target.files[0]);
                    this.packageFile = file;
                    return new Promise((resolve, reject) =>
                    {

                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = () =>
                        {
                            this.image = reader.result;
                            this.boatPictures[this.fileIndex].image = this.image;
                            this.boatPictures[this.fileIndex].name = event.target.files[0].name;
                            this.hasUploadPicture = true;
                            // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                            // console.log(this.packageFile);
                            // this.uploadcardLimitImage(base64Image, file.size, file.name)

                        };
                    })
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Boat Picture',
                        confirmButtonColor: '#00b2a9',
                        text: 'select file less than 3 MB'
                    }).then((result) =>
                    {

                    });
                }
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Boat Picture',
                    confirmButtonColor: '#00b2a9',
                    text: 'Invalid Format!'
                }).then((result) =>
                {

                });
            }


        },

        uploadImg2(event)
        {
            // console.log(this.formFields);
            let file = event.target.files[0];
            this.packageFile = file.name;
            console.log(file);
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.target.files[0].size / 1024 / 1024;

                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () =>
                {
                    // this.image = reader.result;
                    this.packageImage = reader.result;
                    let base64Image = reader.result;
                    // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                    // console.log(this.packageFile);
                    // this.uploadcardLimitImage(base64Image, file.size, file.name)

                };
            })
        },

        chooseFiles1: function (index)
        {
            this.fileIndex = index;
            document.getElementById("fileUpload1").click()
        },
        chooseFiles2: function ()
        {
            // this.fileIndex = index;
            document.getElementById("packageDocument").click()
        },

        chooseFilesGallery: function (index)
        {
            this.fileIndex = index;
            document.getElementById("boatGallery").click()
        },
        deleteBoatDocument(element, index)
        {

            if (element.id)
            {
                let obj = {
                    "id": element.id,
                    "boat_id": this.boatCreationId
                }
                boatServices.removeBoatDocuments(obj).then(res =>
                {
                    if (res.status == 200)
                    {
                        let image;
                        this.boatDocument.forEach((element, i) =>
                        {
                            if (index == i)
                            {
                                image = element.name;
                            }
                        });
                        this.boatDocumentUpload.forEach((element, j) =>
                        {
                            if (element.name == image)
                            {
                                this.boatDocumentUpload.splice(j, 1);
                            }
                        });
                        this.boatDocument.splice(index, 1);
                        this.$swal({
                            title: `Boat Document`,
                            text: 'Boat Picture Deleted successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {

                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Boat Document',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }

                });

            }
            else
            {
                let image;
                this.boatDocument.forEach((element, i) =>
                {
                    if (index == i)
                    {
                        image = element.name;
                    }
                });
                this.boatDocumentUpload.forEach((element, j) =>
                {
                    if (element.name == image)
                    {
                        this.boatDocumentUpload.splice(j, 1);
                    }
                });
                this.boatDocument.splice(index, 1);
            }
        },
        deleteBoatPicture(element, index)
        {

            if (element.id)
            {
                let obj = {
                    "id": element.id,
                    "boat_id": this.boatCreationId
                }
                boatServices.removeBoatPicture(obj).then(res =>
                {
                    if (res.status == 200)
                    {
                        let image;
                        this.boatPictures.forEach((element, i) =>
                        {
                            if (index == i)
                            {
                                image = element.name;
                            }
                        });
                        this.boatGalleryUpload.forEach((element, j) =>
                        {
                            if (element.name == image)
                            {
                                this.boatGalleryUpload.splice(j, 1);
                            }
                        });
                        this.boatPictures.splice(index, 1);
                        this.$swal({
                            title: `Boat Picture`,
                            text: 'Boat Picture Deleted successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {
                            // router.push('/myboat/list')
                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Boat Picture',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }
                });

            }
            else
            {
                let image;
                this.boatPictures.forEach((element, i) =>
                {
                    if (index == i)
                    {
                        image = element.name;
                    }
                });
                this.boatGalleryUpload.forEach((element, j) =>
                {
                    if (element.name == image)
                    {
                        this.boatGalleryUpload.splice(j, 1);
                    }
                });
                this.boatPictures.splice(index, 1);


            }

        },
        addImg()
        {
            this.formFields.push({
                image: ''
            })
            this.adddocu = true;
        },
        addBoatPicture()
        {
            this.boatPictures.push({
                image: ''
            })
            this.hasUploadPicture = false;
        },
        addBoatDocument()
        {
            this.boatDocument.push({
                image: ''
            })
            this.hasUploadImage = false;
        },
        uploadImg1(event)
        {

            console.log(this.formFields);
            let file = event.target.files[0];
            this.boatFile = file;
            return new Promise((resolve, reject) =>
            {

                let fileSize = event.target.files[0].size / 1024 / 1024;

                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () =>
                {
                    this.image = reader.result;
                    console.log(this.formFields, this.fileIndex);
                    this.formFields[this.fileIndex].image = this.image;
                    // base64Image = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                    // this.uploadcardLimitImage(base64Image, file.size, file.name)

                };
            })
        },
        boatInfo(value)
        {

            if (value == 'info')
            {
                console.log(this.info);
                this.step = 1;
                this.info = true;
                this.ownerinfo = false;
                this.packages = false;
                this.boatcondition = false;
                this.document = false;
            }
            if (value == 'ownerinfo')
            {
                this.step = 2;
                this.ownerinfo = true;
                this.info = false;
                this.packages = false;
                this.boatcondition = false;
                this.document = false;
            }
            if (value == 'packages')
            {
                this.step = 3;
                this.packages = true;
                this.info = false;
                this.ownerinfo = false;
                this.boatcondition = false;
                this.document = false;
            }
            if (value == 'boat-condition')
            {
                this.step = 4;
                this.boatcondition = true;
                this.info = false;
                this.ownerinfo = false;
                this.packages = false;
                this.document = false;
            }
            if (value == 'document')
            {
                this.step = 5;
                this.document = true;
                this.info = false;
                this.ownerinfo = false;
                this.packages = false;
                this.boatcondition = false;
            }
        },
        boatCondition()
        {
            // this.paint[0] = 1;

            console.log(this.paint)
            let obj = {
                "boat_id": this.boatCreationId,
                "license_number": this.myboat.license_number ? this.myboat.license_number : null,
                "service_report": this.myboat.report ? this.myboat.report : null,
                "anti_fouling_date": this.myboat.anti_fouling ? this.myboat.anti_fouling : null,
                "paint": this.paint ? this.paint : '0',
                "rooms": this.rooms ? this.rooms : '0',
                "anti_fouling": this.anti_fouling ? this.anti_fouling : '0',
                "polish": this.polish ? this.polish : '0',
                "wash_room": this.wash_room ? this.wash_room : '0',
                "cleanliness": this.cleanliness ? this.cleanliness : '0',
                "life_jacket": this.life_jacket ? this.life_jacket : '0',
                "salon": this.salon ? this.salon : '0',
                "smoke": this.smoke ? this.smoke : '0',
                "fire_extinguisher": this.fire_extinguisher ? this.fire_extinguisher : '0',
                "boat_gear_sound_box": this.gear_sound_box ? this.gear_sound_box : '0',
                "air_conditioner": this.air_conditioner ? this.air_conditioner : '0',
                "bimini": this.bimini ? this.bimini : '0',
                "gear_sound_box": this.gear_sound_box ? this.gear_sound_box : '0',
                "gps": this.gps ? this.gps : '0',
                "depth_sounder": this.depth_sounder ? this.depth_sounder : '0',
                "usb_socket": this.usb_socket ? this.usb_socket : '0',

            }
            boatServices.submitBoatCondition(obj).then(res =>
            {
                if (res.statusCode == 200)
                {

                    // this.boatInfo('document');
                    this.goToStep(5);
                    window.scroll(0, 0);
                    this.boatConditionFields = true;
                }
                else
                {
                    console.log('boat condition error')
                }
            });
        },
        boatOwner()
        {

            console.log(this.myboat.bank_phone);
            if (!this.isValidEmail)
            {
                console.log('email invalid')
                this.myboat.bank_email = '';
                return;
            }
            if (this.myboat.bank_phone.length < 8)
            {
                console.log('phone invalid')
                this.myboat.bank_phone = '';
                this.isPhoneLength = true;
                return;
            }
            else
            {
                this.isPhoneLength = false;
                let obj = {
                    "boat_id": this.boatCreationId,
                    "owner_name": this.myboat.owner_name,
                    "owner_name_ar": this.myboat.owner_name_ar,
                    "owner_type": this.myboat.type,
                    "certificate": this.myboat.certification,
                    "information_id": this.myboat.id_no,
                    "country_id": 1,
                    "unit": this.myboat.unit,
                    "address": this.myboat.street,
                    "district": this.myboat.district,
                    "structure_number": this.myboat.structure_number,
                    "city": this.myboat.city,
                    "phone": this.myboat.bank_phone,
                    "email": this.myboat.bank_email,
                    "bank_name": this.myboat.bank_name,
                    "bank_branch": this.myboat.branch,
                    "bank_iban": this.myboat.iban
                }
                boatServices.submitBoatOwner(obj).then(res =>
                {
                    if (res.statusCode == 200)
                    {

                        // this.boatInfo('packages');
                        this.goToStep(3);
                        window.scrollTo(0, 0);
                        this.ownerInformationFields = true;
                    }
                    else
                    {
                        this.$swal({
                            title: res.data.statusText,
                            confirmButtonColor: '#00b2a9',
                            text: res.data.message,
                            icon: 'error',
                        })
                    }
                }).catch(error =>
                {

                    this.$swal({
                        title: error.response.statusText,
                        confirmButtonColor: '#00b2a9',
                        text: error.response.data.message,
                        icon: 'error',
                    })
                    console.log('error :>> ', error.response)
                    // errorMessages.value = error.response.data.error
                });
            }


        },

        addNewPackage()
        {
            console.log(this.packageItems);
            let tags = [];
            this.packageItems.forEach(element =>
            {
                tags.push(element.id);
            });
            let obj = {
                "boat_id": this.boatCreationId,
                "package_ids": tags ? tags.join() : null,
            }
            console.log(obj);
            boatServices.submitPackage(obj).then(res =>
            {

                if (res.statusCode == 200)
                {
                    this.$swal({
                        title: `Packages added`,
                        text: 'Packages add successfully.',
                        confirmButtonColor: '#00b2a9',
                        icon: 'success',
                    }).then((result) =>
                    {
                        // router.push('/myboat/list')
                    });
                }
                else
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Boat Registration',
                        confirmButtonColor: '#00b2a9',
                        text: 'Something went wrong!',
                    })
                }
            });




        },

        nextPackage()
        {
            let obj = {
                "boat_id": this.boatCreationId,
                "slot_time": this.myboat.slot_time,
                "slot_price": this.myboat.price.replace(/,/g, ''),
                "commission": 0,
                "packageItems": this.packageItems,
                additional_period_rate: this.myboat.additional_period_rate.replace(/,/g, '')
            }
            console.log(obj);
            boatServices.submitPricing(obj).then(res =>
            {
                if (res.statusCode == 200)
                {
                    // this.boatInfo('boat-condition');
                    this.goToStep(4);
                    window.scroll(0, 0);
                    this.packagesPricingFields = true;
                }
                else
                {
                    console.log('boat pricing error')
                }
            });

        },



        createBoatInfo()
        {



            if (this.myboat.marina_id == -1 && this.myboat.marina_name == '')
            {
                this.$swal({
                    icon: 'error',
                    title: '',
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    text: 'Please enter Marina Name or Select from list.',
                });
                return;
            }

            let obj;
            obj = {
                boat_length: this.myboat.boat_length,
                location: this.myboat.location,
                boat_id: this.boatCreationId,
                city_id: this.myboat.city_id,
                country_id: this.myboat.country_id,
                marina_id: this.myboat.marina_id,
                category_id: this.myboat.category_id,
                boat_name: this.myboat.boat_name,
                boat_name_ar: this.myboat.boat_name_ar,
                modal_number: null,
                engine_model: this.myboat.engine_model,
                boat_brand: this.myboat.boat_brand,
                maintenance_time: this.myboat.maintenance_time,
                boat_modal: this.myboat.boat_modal,
                latitude: this.myboat.latitude,
                year: this.myboat.year,
                longitude: this.myboat.longitude,
                marina_name: this.myboat.marina_name,
                marina_name_ar: this.myboat.marina_name_ar,
                port_id: null,
                description: this.myboat.description,
                description_ar: this.myboat.description_ar,

                "status": 1,
            }

            console.log(obj);
            boatServices.submitBoat(obj).then(res =>
            {

                if (res.statusCode == 200)
                {
                    this.boatCreationId = res.data.boat.id;
                    this.goToStep(2);
                    this.boatOwnerFields = true;
                    window.scrollTo(0, 0);
                }
                else
                {
                    console.log('boat Creation Error')
                }
            });
        },

        submit()
        {
            console.log('submit', this.myboat);
            this.$refs.form.validate();
            if (this.$refs.form.validate())
            {
                let data = new FormData();
                for (var key in this.myboat)
                {
                    data.append(key, this.myboat[key]);
                }

                boatServices.submitBoat(data).then(res =>
                {
                    console.log('resresresresres', res)
                    if (res.statusCode == 200)
                    {
                        this.$swal({
                            title: `Boat Registration`,
                            text: 'Boat registered successfully.',
                            confirmButtonColor: '#00b2a9',
                            icon: 'success',
                        }).then((result) =>
                        {
                            router.push('/myboat/list')
                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Boat Registration',
                            confirmButtonColor: '#00b2a9',
                            text: 'Something went wrong!',
                        })
                    }
                });
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Boat Registration',
                    confirmButtonColor: '#00b2a9',
                    text: 'Please fill out all fields.',
                })
            }
        },

        getCities()
        {
            sharedServices.getCities().then(resp =>
            {
                this.cities = resp.data.cities;
            })
        },
        getCountries()
        {
            sharedServices.getCountries().then(resp =>
            {
                console.log(resp)
                this.countries = resp.data.countries;
            })
        },
        getRates()
        {
            sharedServices.getRates().then(resp =>
            {
                console.log(resp)
                for (let index = 0; index < resp?.data?.length; index++)
                {
                    let element = resp.data[index];
                    element['duration'] = element.time + ' ' + element.time_type;
                    this.rates.push(element);
                }
            })
        },
        getCategories()
        {
            sharedServices.getCategories().then(resp =>
            {
                console.log(resp)
                this.categories = resp.data.categories;
            })
        }
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
            icons: {
                mdiPlus,
                mdipluscircle
            },
        }

    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.button-row {
    display: flex;
    flex-wrap: wrap;
}

.button {
    flex-basis: 100%;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 4px;
}

.only-divider .v-divider {
    border-width: 1px !important;
}

.text-blue {
    color: #006fcf;
}


.file-box {
    background: #FFFFFF;
    width: 36px;
    height: 36px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    text-align: center;
}

.text-black {
    color: #000;
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.plus-img {
    margin: auto;
    /* height: 101px; */
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: center; */
    height: 50px;
    margin-top: 46px;
}

.secure-img {
    background: #FFFFFF;
    width: 43px;
    height: 41px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
    position: absolute;
    right: 9px;
    top: 5px;
}

.attach-file {
    height: 20px;
    margin: 4px;
}

.document {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.owner-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.packages {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.boat-condition {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.cross {
    width: 24px;
    height: 22px;
    position: absolute;
    right: -12px;
}

.upload-docu {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
    // height:154px;
}




/* Hide the dropdown icon */


::v-deep .input-tel.is-focused {
    border-color: none !important;
    caret-color: none !important;
}



::v-deep .input-tel__input[data-v-e59be3b4] {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
    border-top: none !important;
    border-right: none !important;
    margin-left: -1px;
    height: 40px;
    min-height: 40px;
    border-left: none !important;
    border-color: none !important;
}



::v-deep .input-tel.has-hint .input-tel__label[data-v-e59be3b4],
.input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}


::v-deep .input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}

::v-deep .country-selector__input[data-v-46e105de] {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 13px;
    border-top: none !important;
    z-index: 0;
    padding-left: 8px;
    color: #000;
    border-left: none !important;
    border-color: none !important;
}

/* Disable the dropdown list */

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 4px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 34px 0px;
}

.document-text {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 2px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 115px;
    font-size: 10px;
    margin: 82px 24px 0px;
}

.bg-img {
    background-image: url('../assets/images/bg_image.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
}

.dummy-img {
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 35px 38px 0px;
}

.bg-white {
    background: white;
}

::v-deep .code-select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    caret-color: none !important;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.bg {
    background: #f1f1f1;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}



::v-deep .country-selector__label[data-v-46e105de] {
    position: absolute;
    top: 3px;
    cursor: pointer;
    display: none !important;
    left: 11px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474;
}




.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

.category-text {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 24px;
    right: 24px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.document-icon {
    position: absolute;
    top: 50px;
    right: 25px;
    width: 88px;
    height: 56px;
}



.diableDiv {
    pointer-events: none;
    opacity: 0.4;
}

.upload-captcha {
    background: #F9F9F9;
    /* backdrop-filter: blur(7px); */
    border-radius: 10px;
    /* padding: 40px 0px; */
    height: 110px;
    width: 290px;
}

.captcha-img {
    top: 32px;
    margin: auto;
    /* height: 23px; */
    width: 200px;
}

.text-fields {
    margin: -4px 0px 0px;
}


::v-deep .step {
    visibility: hidden !important;
}



// ::v-deep .v-stepper__label {
//     align-items: flex-start;
//     display: flex;
//     flex-direction: column;
//     line-height: 1;
//     margin: -60px 0px 39px;
// }

// ::v-deep .v-stepper .v-stepper__step__step {
//     color: white;
//     /* background: red; */
//     background-color:#00b2a9 !important;
// }

::v-deep .theme--light.v-stepper .v-stepper__label {
    /* color: rgba(94, 86, 105, 0.38); */
    color: black !important;
}

@media (min-width: 360px) {
    .v-stepper--alt-labels .v-stepper__header {
        height: auto;
        /* margin: auto; */
        margin: 0px -22px 0px !important;
    }

}
</style>
