import config from '../../config';
import axios from './axiosInit';
let url = config.MYBOAT_REGIS_URL;
const createPackage = async data =>
{
    
    const resp = await axios.post(url +'frontend/pre-boat/onboarding/boat-packages', data)
    return resp.data
}

const getList = async (options, id) =>
{
    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/get-boat-packages?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
       + '&boat_id=' + id )
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const getCategoryList = async (options) =>
{
    try
    {
        const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/categories-list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
            + '&status=' + 1)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCityList = async (options) =>
{
    try
    {
        const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/cities-list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
            + '&status=' + 1)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const getCategoryById = async (id) =>
{
    try
    {
        const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/categories-list?id=' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const getPackages = async () =>
{
    try
    {
        const result = await axios.get('boat_owner_packages/list?per_page=100' + '&status=' + 1)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getContactDetail = async () =>
{
    try
    {
        const result = await axios.get('contact_detail/fetch-contact-detail')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const saveContactDetail = async (body) =>
{
    try
    {
        const result = await axios.post('contact_detail/update-contact-detail', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const saveBoatCategory = async (body) =>
{
    try
    {
        const result = await axios.post('categories/create', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateBoatCategory = async (body) =>
{
    try
    {
        const result = await axios.post('categories/update', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const updatePackage = async (data) =>
{
    try
    {
        const result = await axios.post(url +'frontend/pre-boat/onboarding/update-boat-packages', data)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const categoryUpdate = async (data) =>
{
    try
    {
        const result = await axios.post('categories/update', data)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}



const getsinglePackage = async (id) =>
{
    try
    {
        const result = await axios.get('boat_owner_packages/list?id=' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateStatus = async (url, body) =>
{
    try
    {
        const result = await axios.post(url, body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}


const deleteCategory = async (body) =>
{
    try
    {
        const result = await axios.post('categories/remove', body)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}





export default {
    createPackage,
    getList,
    getCategoryList,
    updatePackage,
    getsinglePackage,
    updateStatus,
    getPackages,
    getContactDetail,
    saveContactDetail,
    saveBoatCategory,
    updateBoatCategory,
    categoryUpdate,
    getCategoryById,
    deleteCategory,
    getCityList

}
