<!-- <template>
    <VueRecaptcha
      :sitekey="siteKey"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @error="handleError"
    ></VueRecaptcha>
  </template> -->
  
  <script>
//   import { computed } from 'vue';
//  import {VueRecaptcha} from 'vue-recaptcha';
  export default ({
    components: {
    //  VueRecaptcha
    },
    // setup() {
    //   const siteKey = computed(() => {
    //     return '6LfVsEsmAAAAABjGuxErkU6xo59Exwcff3IoBaPy';
    //   });
  
    //   const handleError = () => {
    //     // Do some validation
    //   };
  
    //   const handleSuccess = () => {
    //    // Do some validation
    //   };
  
    //   return {
    //     handleSuccess,
    //     handleError,
    //     siteKey,
    //   };
    // }
  });
  </script>