import axios from './axiosInit'

const getCities = async () => {
  try {
    const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/cities-list')
    return result.data
  } catch (e) {
    console.log(e)
    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const getCountries = async () => {
  try {
    const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/countries-list')
    return result.data
  } catch (e) {
    console.log(e)
    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const getRates = async () => {
  try {
    const result = await axios.get('rates')
    return result.data
  } catch (e) {
    console.log(e)
    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}

const getCategories = async () => {
  try {
    const result = await axios.get('https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/categories-list')
    return result.data
  } catch (e) {
    console.log(e)
    // if (e.response && '401' == e.response.status) accountServices.logout();
  }
}


const getMarinaByCityId = async id =>
{
    try
    {
        const result = await axios.get(`https://boat-api.veroke.com/api/frontend/pre-boat/onboarding/marinas-list?city_id=${id}`)

        return result.data
    } catch (e)
    {
        console.log(e)

        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
  getCities,
  getCountries,
  getRates,
  getCategories,
  getMarinaByCityId
}
