import store from '@/store'
import STATUS from './Status'
// import jwtDecode from 'jwt-decode';
import router from '@/router'
// import i18n from '@/locales';
// import usersServices from './users-services';

export const Roles = Object.freeze({
  Admin: 'Admin',
  ProjectAdmin: 'Projects_Admin',
  EntityManager: 'Entity_Manager',
  Province: 'Province',
  PortfolioManager: 'Portfolio_Manager',
})

export const PrivilegesList = Object.freeze({
  ViewPMContracts: 101,
  ViewDashboard: 102,
  FiltercontractsData: 1000,
  DownloadContracts: 103,
  ViewUsers: 104,
  ViewWorkflowLog: 105,
  ViewExternalUsers: 106,
  ViewRoles: 107,
  ViewProjects: 108,
  ViewFMContracts: 109,
  ViewFMDashboard: 110,
  viewFilterByCycle: 111,
  ViewCashFlow: 112,
  ViewEntityDashboard: 113,
  AddPMContracts: 201,
  ImportContracts: 202,
  EditPMContracts: 203,
  DeletePMContracts: 204,
  AddAssets: 205,
  AddFMContracts: 206,
  EditFMContracts: 207,
  DeleteFMContracts: 208,
  AddUsers: 301,
  DeleteUsers: 302,
  EditUsers: 303,
  FreezeUsers: 304,
  EditExternalUsers: 305,
  AddExternalUsers: 306,
  DeleteExternalUsers: 307,
  FreezeExternalUsers: 308,
  ApproveAsProjectsAdmin: 401,
  ApproveAsPMEntityManager: 402,
  ApproveAsDataSteward: 404,
  ApproveAsFMEntityManager: 405,
  RejectContracts: 407,
  ReturnPMContractsForReview: 408,
  ReturnFMContractsForReview: 409,
  ApproveFutureAsProjectsAdmin: 410,
  ApproveFutureAsPMEntityManager: 411,
  MoveProjectsToAnotherEntity: 413,
  AddRoles: 501,
  EditRoles: 502,
  DeleteRoles: 503,
  AddEntities: 601,
  EditEntities: 602,
  DeleteEntities: 603,
  AddProgramms: 604,
  EditProgramms: 605,
  DeleteProgramms: 606,
  AddCycles: 607,
  EditCycles: 608,
  DeleteCycles: 609,
  ListEntitiesPrograms: 610,
  AddEntitiesPrograms: 611,
  EditEntitiesPrograms: 612,
  DeleteEntitiesPrograms: 613,
  AddProjects: 701,
  EditProjects: 702,
  DeleteProjects: 703,
  ReadStrategicIndicators: 801,
  AddStrategicIndicators: 802,
  EditStrategicIndicators: 803,
  DeleteStrategicIndicators: 804,
  ViewPMUsers: 901,
  AddPMUsers: 902,
  EditPMUsers: 903,
  DeletePMUsers: 904,
  FreezePMUsers: 905,
  ViewPMRoles: 906,
  AddPMRoles: 907,
  EditPMRoles: 908,
  DeletePMRoles: 909,
  ViewPMPrivileges: 910,
  ViewFMUsers: 911,
  AddFMUsers: 912,
  EditFMUsers: 913,
  DeleteFMUsers: 914,
  FreezeFMUsers: 915,
  ViewFMRoles: 916,
  AddFMRoles: 917,
  EditFMRoles: 918,
  DeleteFMRoles: 919,
  ViewFMPrivileges: 920,
  EntityZero: 999,
  FMContractsFiltration: 1001,
})

export const logout = async () => {
  store.set('accounts/token', null)
  store.set('accounts/user', null)
  store.set('accounts/error', null)
  store.set('accounts/isAuthenticated', false)
  store.set('accounts/isAdmin', false)
  store.set('accounts/isPMAdmin', false)
  store.set('accounts/isFMAdmin', false)
  store.set('accounts/isRegional', false)
  store.set('accounts/roles', null)
  store.set('accounts/status', STATUS.SUCCESS)
  store.set('accounts/isEntityZero', false)
  store.set('accounts/privileges', null)
  store.set('cycles/current', null)
  store.set('dashboard/activeFilters', null)
  router.push('/login')
}

export const saveError = async error => {
  store.set('accounts/token', null)
  store.set('accounts/user', null)
  store.set('accounts/isAuthenticated', false)
  store.set('accounts/isAdmin', false)
  store.set('accounts/isPMAdmin', false)
  store.set('accounts/isFMAdmin', false)
  store.set('accounts/isRegional', false)
  store.set('accounts/roles', null)
  store.set('accounts/status', STATUS.ERROR)
  store.set('accounts/isEntityZero', false)
  localStorage.removeItem('error')
}

export const hasPrivilege = async privilege => {
  const userPrivileges = store.get('accounts/privileges')
  const PrivilegeId = PrivilegesList[privilege]
  if (userPrivileges.filter(priv => priv.id === PrivilegeId).length > 0) return true
  return false
}

export default {
  Roles,
  PrivilegesList,
  logout,
  saveError,
  hasPrivilege,
}
