import config from '../../config';
import axios from './axiosInit';
let url = config.MYBOAT_REGIS_URL;
const submitBoat = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/create-boat`, data)
    return resp.data
}

const updateBoat = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/update-boat`, data)
    return resp.data
}

const updateBoatOwner = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-owner`, data)
    return resp.data
}

const submitBoatOwner = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-owner`, data);
    if(resp.data)
    {
        return resp.data
    }
    else
    {
        return resp.error.response
    }
}

const submitBoatDocument = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-documents`, data)
    return resp.data
}


const submitPackage = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-packages`, data)
    return resp.data
}

const submitPricing = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-pricing`, data)
    return resp.data
}

const updatePackage = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/update-package`, data)
    return resp.data
}



const submitBoatCondition = async data =>
{
    const resp = await axios.post(url +`frontend/pre-boat/onboarding/boat-condition`, data)
    return resp.data
}


const getBoatData = async (id) =>
{
    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/boat?boat_id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getApplicationList = async (options, id) =>
{
    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
            + '&owner_id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getUserApplication = async id =>
{
    try
    {
        const result = await axios.get('userApplication/' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateApplicationStatus = async (data, url) =>
{

    try
    {
        const result = await axios.post(url, data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const submitBoatGallery = async (data) =>
{

    try
    {
        const result = await axios.post(url +'frontend/pre-boat/onboarding/boat-pictures', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getBoatDocument = async (data) =>
{

    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/get-boat-documents?boat_id=' + data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getBoatPicture = async (data) =>
{

    try
    {

        const result = await axios.get(url +'frontend/pre-boat/onboarding/get-boat-pictures?boat_id=' + data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removeBoatPicture = async (data) =>
{
    try
    {
        const result = await axios.post(url +'frontend/pre-boat/onboarding/remove-boat-picture', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removeBoatDocuments = async (data) =>
{
    try
    {
        const result = await axios.post(url +'frontend/pre-boat/onboarding/remove-boat-document', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removePackage = async (data) =>
{
    try
    {
        const result = await axios.post(url +'frontend/pre-boat/onboarding/remove-boat-package', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getAllOwners = async (data) =>
{
    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/remove-boat-package', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const submitTemplate = async (id) =>
{
    try
    {
        const result = await axios.get(url +'frontend/pre-boat/onboarding/submit?boat_id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}




export default {
    submitBoat,
    updateBoat,
    submitBoatOwner,
    updateBoatOwner,
    submitBoatDocument,
    submitPackage,
    updatePackage,
    getBoatData,
    submitBoatCondition,
    getApplicationList,
    updateApplicationStatus,
    getUserApplication,
    submitPricing,
    submitBoatGallery,
    getBoatDocument,
    getBoatPicture,
    removeBoatPicture,
    removeBoatDocuments,
    removePackage,
    getAllOwners,
    submitTemplate

}
