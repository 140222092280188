import { ref, watch } from '@vue/composition-api';


import packageServices from '@/services/package-services';
export default function usePackageList()
{
    const userListTable = ref([])


    // Table Handlers
    const tableColumns = [
        // {
        //     text: 'ID',
        //     align: 'start',
        //     value: 'id',
        //     sortable: false,
        //     align: 'center'
        // },
        { text: 'Package Name', value: 'name', sortable: false, align: 'center' },
        { text: 'Price', value: 'price', sortable: false, align: 'center' },
        { text: 'Description', value: 'description', sortable: false, align: 'center' },
        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const searchQuery = ref('')
    const packageId = ref('')
    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const selectedTableData = ref([])

    const fetchUsers = () =>
    {
        if (packageId?.value)
        {
            packageServices.getList(options, packageId?.value).then(resp =>
            {
                // const { filteredData, total } = response.data
                if (resp.statusCode == 200)
                {
                    userListTable.value = resp.data.packages
                    totalUserListTable.value = resp.data.pagination.count
                }
                loading.value = false
                console.log(resp)
            })
        }



    }

    watch([searchQuery, statusFilter, options, packageId], () =>
    {
        // start loading
        loading.value = true
        selectedTableData.value = []
        fetchUsers()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        options,
        packageId,
        userListTable,
        statusFilter,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchUsers,
        resolveRole,
    }
}
