<template>
    <div>
        <template>
            <v-row justify="center">
                <v-dialog v-model="modal"
                          persistent
                          max-width="600px">
                    <v-card>
                        <div class="float-right mr-5 mt-3 px-2 py-1 rounded-circle"
                             @click="closeDialog"
                             style="background-color:#FAFAFA;cursor: pointer;">
                            <v-icon color="#00B2A9"
                                    size="15"
                                    class="mt-n1 mx-auto">mdi-close-circle-outline</v-icon>
                        </div>
                        <v-card-title>
                            <div class="color mt-2 mx-4">{{ $t('Packages Details') }}</div>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form"
                                    class="multi-col-validation">
                                <v-row dense
                                       class="mt-2 mb-0">
                                    <v-col cols="12"
                                           lg="6"
                                           md="4"
                                           sm="6">
                                        <p class="font-weight-black text-body-2 text-black ml-5">
                                            {{ $t('Package Name') }} *
                                        </p>
                                        <v-card-text class="pb-0">
                                            <v-text-field dense
                                                          :disabled="disableForm"
                                                          :placeholder="$t('Enter Package Name')"
                                                          :rules="genericRules"
                                                          v-model="packageName"></v-text-field>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12"
                                           lg="6"
                                           md="4"
                                           sm="6">
                                        <p class="font-weight-black text-body-2 text-black ml-5">
                                            {{ $t('Package Name Ar') }} *
                                        </p>
                                        <v-card-text class="pb-0">
                                            <v-text-field dense
                                                          :disabled="disableForm"
                                                          :placeholder="$t('Enter Package Name Ar')"
                                                          :rules="genericRules"
                                                          v-model="packageNameAr"></v-text-field>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12"
                                           lg="6"
                                           md="4"
                                           sm="6">
                                        <p class="font-weight-black text-body-2 text-black ml-5">
                                            {{ $t('Package Price (SAR)') }} *
                                        </p>
                                        <v-card-text class="pb-0">
                                            <v-text-field dense
                                                          :disabled="disableForm"
                                                          :rules="[priceRules.numbers, priceRules.required]"
                                                          :placeholder="$t('Enter Price in SAR')"
                                                          v-model="packagePrice"></v-text-field>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-row dense
                                       class="mb-0">
                                    <v-col cols="12">
                                        <v-card-text class="pb-0">
                                            <p class="font-weight-black text-body-2 text-black">

                                               {{ $t('Package Description') }} *</p>
                                            <v-textarea rows="3"
                                                        outlined
                                                        :disabled="disableForm"
                                                        :rules="genericRules"
                                                        autocomplete="Category Description"
                                                        :placeholder="$t('Enter Package Description')"
                                                        v-model="packageDescription">
                                            </v-textarea>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-row dense
                                       class="mb-0">
                                    <v-col cols="12">
                                        <v-card-text class="pb-0">
                                            <p class="font-weight-black text-body-2 text-black">

                                               {{ $t('Package Description Ar') }} *</p>
                                            <v-textarea rows="3"
                                                        outlined
                                                        :disabled="disableForm"
                                                        :rules="genericRules"
                                                        class="write-ar"
                                                        autocomplete="Category Description"
                                                        :placeholder="$t('Enter Package Description Ar')"
                                                        v-model="packageDescriptionAr">
                                            </v-textarea>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-row dense
                                       class="mb-2">
                                    <v-col cols="12">
                                        <v-card-text class="pb-0">
                                            <p class="font-weight-black text-body-2 text-black">
                                                {{ $t('Upload Image') }}</p>
                                            <div class="upload-docu mr-3"
                                                 style="padding:0px !important"
                                                 tile>
                                                <input type="file"
                                                       name="file"
                                                       id="packageDocument"
                                                       class="hidden-input"
                                                       ref="filePackage"
                                                       @change="uploadImg2"
                                                       hidden
                                                       accept=".jpg,.jpeg,.png" />
                                                <v-img v-if="packageImage"
                                                       :src="packageImage"
                                                       width="100%"
                                                       style="border-radius: 10px;height: 100%;max-height: 154px;"
                                                       class="me-2"></v-img>
                                                <span v-if="packageImage && !disableForm"
                                                      class="category-icon"
                                                      @click="deleteImg" style="cursor: pointer;">
                                                    <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                                           height="21px"
                                                           width="22px"></v-img>
                                                </span>
                                                <label v-if="!packageImage"
                                                       for="fileInput"
                                                       class="file-label">
                                                    <img class="imge"
                                                         :src="require(`@/assets/images/dummy-img.png`)"
                                                         alt="">
                                                </label>
                                                <div v-if="!packageImage && !disableForm"
                                                     style="cursor: pointer;"
                                                     class="upload-img"
                                                     @click="chooseFiles2()">
                                                    {{ $t('Upload Image') }}
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-row dense
                                       class="mb-0">
                                    <v-col cols="12"
                                           lg="6"
                                           md="4"
                                           sm="6">
                                        <v-card-text class="">
                                            <p class="font-weight-black text-body-2 text-black mb-0">
                                                {{ $t('Package Items') }} *</p>
                                            <v-text-field :placeholder="$t('Enter Package Items')"
                                                          variant="underlined"
                                                          append-icon="mdi-plus-circle"
                                                          :disabled="disableForm"
                                                          class="text-fields"
                                                          @click:append="addPackage"
                                                          v-model="packageItem"></v-text-field>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-row dense
                                       class="mb-0">
                                    <v-col cols="12">
                                        <v-card-text class="pb-0">
                                            <p class="font-weight-black text-body-2 text-black">
                                                {{ $t('Added Package Items') }}
                                            </p>
                                            <div class="package-item mt-3">
                                                <!-- <v-chip small
                                                        v-for="( field, index ) in  packageItems"
                                                        :key="field"
                                                        :disabled="disableForm"
                                                        label
                                                        class="mr-3 mt-2"
                                                        style="background-color: #F3F3F3; color: black">
                                                    {{ field }} &nbsp <span @click=remove(index)
                                                          class="cross">x</span>
                                                </v-chip> -->
                                                <v-chip small   
                                                        v-for="( field, index ) in  packageItems"
                                                        :key="index"
                                                        label
                                                        close
                                                        :disabled="disableForm"
                                                        class="mr-3 mt-1"
                                                        @click:close="remove(index)"
                                                        style="background-color: #F3F3F3; color: black">
                                                    <span style="font-size: 15px;">{{ field }} &nbsp;</span>
                                                </v-chip>
                                            </div>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <v-card-actions class="mt-5"
                                            v-if="!disableForm">
                                <v-btn class="text-capitalize font-weight-regular rounded-lg px-9 ml-1 white--text"
                                       color="#00B2A9"
                                       :disabled="!fieldIsFilled"
                                       @click="addNewPackage">
                                       {{ $t('Add Package') }}</v-btn>
                            </v-card-actions>
                        </v-card-text>

                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        modal: {
            default: false,
            type: Boolean
        },
        boatCreationId: {
            default: null,
            type: Number
        },
        disableForm: {
            default: false,
            type: Boolean
        },
        packageInfos: {
            default()
            {
                return {}
            },
            type: Object
        },
    },
    data: () => ({
        packageName: '',
        packageNameAr: '',
        packagePrice: '',
        packageDescription: '',
        packageDescriptionAr: '',
        packageImage: '',
        packageItems: [],
        packageItem: '',
        packageFile: null,
        packageId: null,
        priceRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[0-9]*$/.test(v) || 'Only numbers are allowed'
        },
        genericRules: [
            v => !!v || 'Field is required'
        ],
    }),
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        packageInfos: {
            immediate: true,
            handler(newVal)
            {
                
                console.log(this.genericRules);
                this.localObject = Object.assign({}, newVal);
                console.log(this.localObject);
                if (this.localObject?.id)
                {
                    
                    this.packageItems = [];
                    this.packageItem = '';
                    this.packageName = this.localObject.name
                    this.packageNameAr = this.localObject.name_ar
                    this.packagePrice = this.localObject.price
                    this.packageDescription = this.localObject.description
                    this.packageDescriptionAr = this.localObject.description_ar
                    if (this.localObject.image)
                    {
                        this.packageImage = `http://api-staging.myboat.co/uploads/` + this.localObject.image;
                        this.packageFile = this.packageImage;
                    }
                    else
                    {
                        this.packageImage = null;
                    }

                    let item = this.localObject.tags ? this.localObject.tags.split(",") : null;
                    if (item.length)
                    {
                        item.forEach(element =>
                        {
                            this.packageItems.push(element);
                        });
                    }
                    else
                    {
                        this.packageItems = this.localObject.tags;
                    }
                }
                else
                {
                    this.packageItems = [];
                    this.packageItem = '';
                    this.packageImage = null
                    this.packageFile = null;
                    this.packageName = '';
                    this.packageNameAr = '';
                    this.packagePrice = '';
                    this.packageDescription = '';
                    this.packageDescriptionAr = '';
                    // this.$refs.form.resetValidation();
                }

            }
        }
    },
    computed: {
        fieldIsFilled()
        {
            return this.packageName && this.packageNameAr && this.packagePrice && this.packageDescription && this.packageDescriptionAr && this.packageItems.length != 0 && this.packageFile;
        },
    },
    methods: {
        addNewPackage()
        {
            if (!this.packageItems.length)
            {
                return;
            }
            if (this.localObject.id)
            {
                console.log(this.packageFile);
                const formData = new FormData();
                formData.append('name', this.packageName);
                formData.append('name_ar', this.packageNameAr);
                formData.append('description', this.packageDescription);
                formData.append('description_ar', this.packageDescriptionAr);
                formData.append('price', this.packagePrice);
                formData.append('tags', this.packageItems.join());
                formData.append('id', this.localObject.id);
                formData.append('boat_id', this.boatCreationId);
                formData.append('package_image', this.packageFile);
                this.$emit('updatePackageData', formData);
                // this.$refs.form.resetValidation();
            }
            else
            {
                const formData = new FormData();
                formData.append('name', this.packageName);
                formData.append('name_ar', this.packageNameAr);
                formData.append('description', this.packageDescription);
                formData.append('description_ar', this.packageDescriptionAr);
                formData.append('price', this.packagePrice);
                formData.append('tags', this.packageItems.join());
                formData.append('boat_id', this.boatCreationId);
                formData.append('package_image', this.packageFile);
                this.$emit('createPackageData', formData);
                // this.$refs.form.resetValidation();
            }

        },
        chooseFiles2: function ()
        {
            document.getElementById("packageDocument").click()
        },
        addPackage()
        {
            if(this.packageItem == undefined || this.packageItem == '')
            {
                return;
            }
            if (this.packageItems.length < 5 && !this.packageItems.includes(this.packageItem))
            {
                if (this.packageItem.includes(","))
                {
                    let value = this.packageItem.split(",");

                    value.forEach(element =>
                    {
                        if (this.packageItems.length < 5 && !this.packageItems.includes(element))
                        {
                            this.packageItems.push(element);
                        }
                    });
                    this.packageItem = '';
                }
                else
                {
                    if (this.packageItems.length < 5)
                    {
                        this.packageItems.push(this.packageItem);
                    }
                    this.packageItem = '';
                }
            }
        },

        remove(index)
        {
            this.packageItems.splice(index, 1);
        },
        deleteImg()
        {
            this.packageImage = null;
            this.packageFile = null;
            console.log('this.$ref.filePackage', this.$refs.filePackage.value)
            this.$refs.filePackage.value = null;
        },
        uploadImg2(event)
        {
            // console.log(this.formFields);

            let file = event.target.files[0];
            let type = file.type.split('/');
            if (type[1] == 'png' || type[1] == 'jpeg' || type[1] == 'jpg')
            {
                console.log(file);
                this.packageFile = file;
                return new Promise((resolve, reject) =>
                {
                    let fileSize = event.target.files[0].size / 1024 / 1024;

                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = () =>
                    {
                        // this.image = reader.result;
                        this.packageImage = reader.result;


                    };
                })
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Boat Picture',
                    confirmButtonColor: '#00b2a9',
                    text: 'Invalid Format!'
                }).then((result) =>
                {

                });
            }

        },
        closeDialog()
        {
            this.$refs.form.resetValidation();
            this.packageInfos = {};
            this.$emit('closed');
            return this.modal = !this.modal;
        }
    }
}
</script>

<style scoped>
.color {
    color: #00B2A9;
}

.upload-docu {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 4px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 34px 0px;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

/* .package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding: 30px;
} */
</style>